/* You can add global styles to this file, and also import other style files */


/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzgyYmIyNyIsIj9lcjwjZGFlYmJlIiwiO2VyPCM2NWE0MTd$LCIlPmBePCM3OTc5NzkiLCI~ZXI8I2Q3ZDdkNyIsIjtlcjwjNWM1YzVjfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.


// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Theme Config

body {
  --primary-color: #82bb27;
  --primary-lighter-color: #daebbe;
  --primary-darker-color: #65a417;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
}

$mat-primary: (
  main: #82bb27,
  lighter: #daebbe,
  darker: #65a417,
  200: #82bb27,
  // For slide toggle,
  contrast : (main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);


body {
  --accent-color: #797979;
  --accent-lighter-color: #d7d7d7;
  --accent-darker-color: #5c5c5c;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #797979,
  lighter: #d7d7d7,
  darker: #5c5c5c,
  200: #797979,
  // For slide toggle,
  contrast : (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast : (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
;

$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);
$altTheme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: 'Roboto';
  }
}


@import "~bootstrap/dist/css/bootstrap.min.css";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@font-face {
  font-family: "Switzer-Variable";
  src:
    url("assets/fonts/Switzer-Variable.woff2") format("woff2"),
    url("assets/fonts/Switzer-Variable.woff") format("woff"),
    url("assets/fonts/Switzer-Variable.ttf") format("truetype");
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-VariableItalic";
  src:
    url("assets/fonts/Switzer-VariableItalic.woff2") format("woff2"),
    url("assets/fonts/Switzer-VariableItalic.woff") format("woff"),
    url("assets/fonts/Switzer-VariableItalic.ttf") format("truetype");
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Switzer-Thin";
  src:
    url("assets/fonts/Switzer-Thin.woff2") format("woff2"),
    url("assets/fonts/Switzer-Thin.woff") format("woff"),
    url("assets/fonts/Switzer-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-ThinItalic";
  src:
    url("assets/fonts/Switzer-ThinItalic.woff2") format("woff2"),
    url("assets/fonts/Switzer-ThinItalic.woff") format("woff"),
    url("assets/fonts/Switzer-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Switzer-Extralight";
  src:
    url("assets/fonts/Switzer-Extralight.woff2") format("woff2"),
    url("assets/fonts/Switzer-Extralight.woff") format("woff"),
    url("assets/fonts/Switzer-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-ExtralightItalic";
  src:
    url("assets/fonts/Switzer-ExtralightItalic.woff2") format("woff2"),
    url("assets/fonts/Switzer-ExtralightItalic.woff") format("woff"),
    url("assets/fonts/Switzer-ExtralightItalic.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Switzer-Light";
  src:
    url("assets/fonts/Switzer-Light.woff2") format("woff2"),
    url("assets/fonts/Switzer-Light.woff") format("woff"),
    url("assets/fonts/Switzer-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-LightItalic";
  src:
    url("assets/fonts/Switzer-LightItalic.woff2") format("woff2"),
    url("assets/fonts/Switzer-LightItalic.woff") format("woff"),
    url("assets/fonts/Switzer-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Switzer-Regular";
  src:
    url("assets/fonts/Switzer-Regular.woff2") format("woff2"),
    url("assets/fonts/Switzer-Regular.woff") format("woff"),
    url("assets/fonts/Switzer-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-Italic";
  src:
    url("assets/fonts/Switzer-Italic.woff2") format("woff2"),
    url("assets/fonts/Switzer-Italic.woff") format("woff"),
    url("assets/fonts/Switzer-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Switzer-Medium";
  src:
    url("assets/fonts/Switzer-Medium.woff2") format("woff2"),
    url("assets/fonts/Switzer-Medium.woff") format("woff"),
    url("assets/fonts/Switzer-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-MediumItalic";
  src:
    url("assets/fonts/Switzer-MediumItalic.woff2") format("woff2"),
    url("assets/fonts/Switzer-MediumItalic.woff") format("woff"),
    url("assets/fonts/Switzer-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Switzer-Semibold";
  src:
    url("assets/fonts/Switzer-Semibold.woff2") format("woff2"),
    url("assets/fonts/Switzer-Semibold.woff") format("woff"),
    url("assets/fonts/Switzer-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-SemiboldItalic";
  src:
    url("assets/fonts/Switzer-SemiboldItalic.woff2") format("woff2"),
    url("assets/fonts/Switzer-SemiboldItalic.woff") format("woff"),
    url("assets/fonts/Switzer-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Switzer-Bold";
  src:
    url("assets/fonts/Switzer-Bold.woff2") format("woff2"),
    url("assets/fonts/Switzer-Bold.woff") format("woff"),
    url("assets/fonts/Switzer-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-BoldItalic";
  src:
    url("assets/fonts/Switzer-BoldItalic.woff2") format("woff2"),
    url("assets/fonts/Switzer-BoldItalic.woff") format("woff"),
    url("assets/fonts/Switzer-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Switzer-Extrabold";
  src:
    url("assets/fonts/Switzer-Extrabold.woff2") format("woff2"),
    url("assets/fonts/Switzer-Extrabold.woff") format("woff"),
    url("assets/fonts/Switzer-Extrabold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-ExtraboldItalic";
  src:
    url("assets/fonts/Switzer-ExtraboldItalic.woff2") format("woff2"),
    url("assets/fonts/Switzer-ExtraboldItalic.woff") format("woff"),
    url("assets/fonts/Switzer-ExtraboldItalic.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Switzer-Black";
  src:
    url("assets/fonts/Switzer-Black.woff2") format("woff2"),
    url("assets/fonts/Switzer-Black.woff") format("woff"),
    url("assets/fonts/Switzer-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-BlackItalic";
  src:
    url("assets/fonts/Switzer-BlackItalic.woff2") format("woff2"),
    url("assets/fonts/Switzer-BlackItalic.woff") format("woff"),
    url("assets/fonts/Switzer-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

@media (min-width: 1860px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1720px;
  }
}

/* Esta mediaquery sobraria */
@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1420px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

p {
  margin: 0;
}

.btn-primary {
  cursor: pointer;
  text-transform: uppercase;
  background: transparent;
  border-radius: 34px;
  font-size: 16px;
  border: 2px solid #82bb27;
  color: #82bb27;
  padding: 15px 45px;
  font-family: "Switzer-Medium";
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.btn-primary:hover {
  font-size: 16px;
  background-color: #82bb27;
  color: white;
}

.btn-secondary {
  cursor: pointer;
  font-size: 16px;
  background-color: #82bb27;
  color: white;
  text-transform: uppercase;
  border-radius: 34px;
  padding: 15px 45px;
  font-family: "Switzer-Medium";
  display: inline-block;
  position: relative;
  border: 2px solid #82bb27;
  transition: 0.5s;
}

.btn-secondary:hover {
  background: transparent;
  border: 2px solid #82bb27;
  color: #82bb27;
}

.btn-primary:hover:after {
  visibility: visible;
}

.bold-font {
  font-family: "Switzer-Semibold";
}

// body {
//     cursor: none !important;
// }

#cursor,
.cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  mix-blend-mode: difference;
  pointer-events: none;
  transition: transform 0.15s ease-in-out;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

#cursorTxt,
.cursorTxt {
  font-size: 36px;
  color: #262020;
  z-index: 1000;
  text-decoration: underline;
  position: absolute;
  display: none;
}

p {
  position: relative !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.fadeInAnimation {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.generic-dialog {
  overflow: auto !important;
}

.content-wrapper {
  padding-top: 95px;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #82bb27 !important;
}

.mdc-tab__text-label {
  color: #4c4c4c !important;
}

.swiper-button-prev:after {
  color: white !important;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 50px 60px -60px;
}

.swiper-button-next:after {
  color: white !important;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 50px 60px -60px;
}